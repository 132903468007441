$(document).ready(function() {
	if(!document.querySelector('.C0036')) return false;

	var $snsArea = $('.C0036 .sns-area');
	$snsArea.find('.sns').attr('aria-expanded', false);
	$snsArea.find('.sns').on('click', function(e){
		e.preventDefault();
		var $snsLayer = $(this).closest('.sns-area').find('.list');
		if($snsLayer.hasClass('active')) {
			$snsLayer.removeClass('active');
			$(this).attr('aria-expanded', false);
		} else {
			$snsLayer.addClass('active');
			$(this).attr('aria-expanded', true);
			/* PJTWAUS-1_KJB : 20200120 add */
			var focusObj = $snsLayer.find('a[href], area[href], input:not([disabled]), input:not([readonly]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]');
			var focusFirst = focusObj.first();
			var focusLast = focusObj.last();
			
			focusLast.on('keydown', function (e) {
				if ((e.which === 9 && !e.shiftKey)) {
					e.preventDefault();
					$snsLayer.removeClass('active');
					$(this).attr('aria-expanded', false);
					_this.focus();
				}
			});
			focusFirst.on('keydown', function (e) {
				if ((e.which === 9 && e.shiftKey)) {
					e.preventDefault();
					$snsLayer.removeClass('active');
					$(this).attr('aria-expanded', true);
					_this.focus();
				}
			});
			/*// PJTWAUS-1_KJB : 20200120 add */
		}
	});
	
});
